import {
    AppBar,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Popover,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HomeIcon from '@material-ui/icons/Home';
import clsx from 'clsx';
import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useOktaAuth } from '@okta/okta-react';
import { NavLink, useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { UserClaims } from '@okta/okta-auth-js';
import ePlusLogo from '../../assets/logo_transparent_simple.png';

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) => ({
    '@global': {
        '::-webkit-scrollbar': {
            width: '1.4em',
            height: '1.4em',
        },
        '::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            borderWidth: 3,
            borderColor: 'rgba(0,0,0,0)',
            borderStyle: 'solid',
            background: 'rgba(0,0,0,0.4)',
            backgroundClip: 'padding-box',
            '&:hover': {
                background: 'rgba(0,0,0,0.7)',
                backgroundClip: 'padding-box',
            },
        },
    },
    root: {
        display: 'flex',
        height: '100%',
        // backgroundColor: theme.palette.background.paper,
        // overflowY: 'auto',
        // paddingBottom: theme.spacing(6),
    },
    offset: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(1),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: `calc(100% - ${drawerWidth}px)`,
    },
    innerContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
        // height of appbar
        height: `calc(100% - 64px)`,
        overflowY: 'auto',
    },
    logo: {
        height: '42px',
        padding: theme.spacing(0, 2, 0, 1),
    },
    name: {
        padding: 16,
    },
}));

const Base: React.FunctionComponent = ({ children }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [userInfo, setUserInfo] = React.useState<UserClaims | null>(null);
    const { authState, oktaAuth } = useOktaAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openUserMenu = Boolean(anchorEl);

    const handleMenu = (event: React.ChangeEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // TODO: Switch to redux and maybe saga

    React.useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]);

    // const logout = React.useCallback(async () => oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/logout` }), [oktaAuth]);

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen((value) => !value)}>
                        <MenuIcon />
                    </IconButton>
                    <Link component={RouterLink} to="/">
                        <img src={ePlusLogo} alt="ePlus Logo" className={classes.logo} />
                    </Link>
                    <Typography variant="h6" style={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                        Cloud FinOps Maturity
                    </Typography>
                    {authState?.isAuthenticated && (
                        <div>
                            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                                <AccountCircle />
                            </IconButton>
                            <Popover
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={openUserMenu}
                                onClose={handleClose}
                            >
                                <div className={classes.name}>
                                    {userInfo ? (
                                        <>
                                            <Typography variant="h5" color="inherit" noWrap>
                                                {`${userInfo.given_name} ${userInfo.family_name}`}
                                            </Typography>
                                            <Typography color="textSecondary" variant="subtitle1" noWrap>
                                                {userInfo.email}
                                            </Typography>
                                        </>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </div>
                                <Divider />

                                {/* <MenuItem onClick={logout}>Logout</MenuItem> */}
                            </Popover>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar} />
                <Divider />
                <List>
                    <ListItem button key="maturityModelHome" component={NavLink as any} to="/" selected={useRouteMatch('/')?.isExact}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button key="maturityModelCreation" component={NavLink as any} to="/maturityModel" selected={Boolean(useRouteMatch('/maturityModel'))}>
                        <ListItemIcon>
                            <QuestionAnswerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Maturity Model" />
                    </ListItem>
                </List>
            </Drawer>
            <div className={classes.content}>
                <div className={classes.offset} />
                <div className={classes.innerContent}>{children}</div>
            </div>
        </div>
    );
};

export default Base;
