import { configureStore, ThunkAction, Action, Reducer } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import counterReducer from '../modules/counter/counterSlice';
import { maturityModelApi } from './MaturityModelApi';

export const AppHistory = createBrowserHistory();

export const store = configureStore({
    reducer: {
        router: connectRouter(AppHistory) as Reducer<RouterState<any>>,
        [maturityModelApi.reducerPath]: maturityModelApi.reducer,
        counter: counterReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(AppHistory)).concat(maturityModelApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
