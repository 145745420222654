import React from 'react';
import { Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';

const MaturityModelCreator = React.lazy(() => import('./MaturityModelCreator'));
const MaturityModelResult = React.lazy(() => import('./MaturityModelResult'));
const MaturityModelResultVerbose = React.lazy(() => import('./MaturityModelResultVerbose'));

const MaturityModelRoutes = (): React.ReactNode[] => [
    <Redirect key="maturityModelRedirect" from="/maturityModel" exact to="/maturityModel/create/start" />,

    <SecureRoute key="maturityModelCreator" path="/maturityModel/create" component={MaturityModelCreator} />,
    <SecureRoute key="result" path="/maturityModel/:id/result" component={MaturityModelResult} />,
    <SecureRoute key="maturityModelDetailedResults" path="/maturityModel/:id/detailedResults" component={MaturityModelResultVerbose} />,
];

export default MaturityModelRoutes;
