import { createTheme } from '@material-ui/core';
// import type {} from '@material-ui/lab/themeAugmentation';

const AppTheme = createTheme({
    palette: {
        primary: { main: '#114989' },
        secondary: { main: '#00D4A6' },
    },
});

export default AppTheme;
