import { makeStyles, Theme } from '@material-ui/core';

const useCommonStyles = makeStyles((theme: Theme) => ({
    centerScreen: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    },
    error: {
        color: theme.palette.error.main,
    },
    heightInherit: {
        height: 'inherit',
    },
}));

export default useCommonStyles;
