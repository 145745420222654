import { CircularProgress, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Security } from '@okta/okta-react';
import React, { Suspense } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import AppTheme from './util/appTheme';
import Base from './modules/base/Base';
import MaturityModelRoutes from './modules/maturityModel/Routes';
import useCommonStyles from './util/commonStyles';
import oktaAuth from './modules/auth/OktaAuth';
import AuthRoutes from './modules/auth/Routes';
import HomeRoutes from './modules/home/Routes';

function App() {
    const commonClasses = useCommonStyles();
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={AppTheme}>
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                    <Base>
                        <Suspense
                            fallback={
                                <div className={commonClasses.centerScreen}>
                                    <CircularProgress />
                                </div>
                            }
                        >
                            <Switch>{[...MaturityModelRoutes(), ...AuthRoutes(), ...HomeRoutes()]}</Switch>
                        </Suspense>
                    </Base>
                </Security>
            </ThemeProvider>
        </>
    );
}

export default App;
